<template>
  <div class="form">
    <div class="wrapper">
      <Backlink title="questionnaire" service="refCredit" :backlink="backlink"/>
      <div class="content illustration illustration_form">
        <div class="content__container">
          <form @submit.prevent="submitHandler">
            <p class="promo promo_blocks2">{{ "s4p19_text_2" | localize }}</p>
            <div class="form__group">
              <v-textarea
                solo
                auto-grow
                v-model="text"
                @input="checkForm($v.text)"
                :class="{ invalid: $v.text.$dirty && !$v.text.required }"
                :placeholder="'s4p19_placeholder_1' | localize"
              ></v-textarea>
            </div>

            <div class="error__container">
              <p class="error" v-if="error">{{ error | localize }}</p>
            </div>

            <button class="button button__form" :class="{ button__form_disabled: !success }">
              {{ "form_result" | localize }}
            </button>
          </form>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { required } from 'vuelidate/lib/validators'
import Backlink from '@/components/base/Backlink.vue'
import { saveMixin } from '@/mixins/saveMixin.js'
import { commonMixin } from '@/mixins/commonMixin.js'

export default {
  name: 'refCredit_something_else',
  components: { Backlink },
  mixins: [saveMixin, commonMixin],
  data: () => ({
    text: null,
    success: false,
    backlink: '/',
    error: null
  }),
  created () {
    const refCredit = this.$cookies.get('refCredit') || {}
    this.text = refCredit.text || null
    this.backlink = refCredit.has_delay === 1 ? '/refinance-credit/about-delay' : '/refinance-credit/delay'
  },
  validations: {
    text: { required }
  },
  methods: {
    checkForm () {
      if (this.$v.$invalid) {
        this.$v.text.$touch()
        this.success = false
      } else {
        this.success = true
      }
    },
    submitHandler () {
      if (this.$v.$invalid) {
        this.$v.text.$touch()
        this.success = false
        return
      }

      const refCredit = this.$cookies.get('refCredit') || {}
      refCredit.text = this.text
      this.$cookies.set('refCredit', refCredit)
      this.saveServiceInfo('refCredit', '/refinance-credit/payment', { text: this.text })
    }
  }
}
</script>
